import React from 'react';
import keycloak from './keycloak';
import {isUserAdmin} from './utils/accessControl';

const Users = React.lazy(() => import('./views/Users/Users'));
const User = React.lazy(() => import('./views/Users/User'));
const Assessments = React.lazy(() => import('./views/Assessments/Assessments'));
const Assessment = React.lazy(() => import('./views/Assessments/Assessment'));
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const DownloadAssessment = React.lazy(() =>
	import('./views/Assessments/DownloadAssessment'),
);
const ConductAssessment = React.lazy(() =>
	import('./views/Dashboard/ConductAssessment'),
);
const EvaluateAssessment = React.lazy(() =>
	import('./views/Dashboard/EvaluateAssessment'),
);
const AssessmentResults = React.lazy(() =>
	import('./views/Dashboard/AssessmentResults'),
);

const Results = React.lazy(() => import('./views/Results/Results'));
const DeletedResults = React.lazy(() =>
	import('./views/Results/DeletedResults'),
);
const UnFinishedResults = React.lazy(() =>
	import('./views/Results/UnFinishedResults'),
);
const Result = React.lazy(() => import('./views/Results/Result'));
const Help = React.lazy(() => import('./views/Help/Help'));

const getRoutes = auth => {
	if (!auth) return [];

	const routes = [
		{path: '/dashboard', name: 'Dashboard', component: Dashboard},

		// User assessment paths
		{
			path: '/conduct-assessment/:id',
			exact: true,
			name: 'Pre-Assessment Details',
			component: ConductAssessment,
		},
		{
			path: '/evaluate-assessment/:id',
			exact: true,
			name: 'Assessment',
			component: EvaluateAssessment,
		},
		{
			path: '/assessment-results/:id',
			exact: true,
			name: 'Assessment Results',
			component: AssessmentResults,
		},
		{
			path: '/results',
			exact: true,
			name: 'Completed Assessments',
			component: Results,
		},
		{
			path: '/unfinished-results',
			exact: true,
			name: 'Unfinished Assessments',
			component: UnFinishedResults,
		},
		{
			path: '/results/:id',
			exact: true,
			name: 'View Results',
			component: Result,
		},
	];

	try {
		if (isUserAdmin(auth)) {
			routes.push({
				path: '/deleted-results',
				exact: true,
				name: 'Assessment Download',
				component: DeletedResults,
			});
			routes.push({
				path: '/assessment/download/:assessmentId',
				exact: true,
				name: 'Assessment Download',
				component: DownloadAssessment,
			});

			// Admin User paths
			routes.push({
				path: '/users',
				exact: true,
				name: 'Users',
				component: Users,
			});
			routes.push({
				path: '/users/:id',
				exact: true,
				name: 'User Details',
				component: User,
			});
			routes.push({
				path: '/new/user',
				exact: true,
				name: 'New User',
				component: User,
			});

			// Admin Assessment paths
			routes.push({
				path: '/assessments',
				exact: true,
				name: 'Start Assessment',
				component: Assessments,
			});
			routes.push({
				path: '/assessments/:id',
				exact: true,
				name: 'Assessment Details',
				component: Assessment,
			});
			routes.push({
				path: '/new/assessment',
				exact: true,
				name: 'New Assessment',
				component: Assessment,
			});
		}
	} catch (e) {
		keycloak.logout();
	}

	routes.push({
		path: '/help',
		exact: true,
		name: 'Help & Feedback',
		component: Help,
	});

	return routes;
};

export default getRoutes;
