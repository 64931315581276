import localForage from 'localforage';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistReducer, persistStore} from 'redux-persist';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const persistConfig = {
	key: 'root',
	storage: localForage,
	whitelist: [
		'assessmentTemplates',
		'currentAssessment',
		'unFinishedAssessments',
	],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({});

const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(thunk, promise)),
);

const persistor = persistStore(store);

export {persistor, store};
