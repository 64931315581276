import {ReactKeycloakProvider} from '@react-keycloak/web';
import React from 'react';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import App from './App';
import Loading from './components/Loading';
import './index.css';
import keycloak from './keycloak';
import './polyfill';
import {loginUser} from './redux/actionCreators/authActionCreators';
import {persistor, store} from './redux/store';

ReactDOM.render(
	<ReactKeycloakProvider
		authClient={keycloak}
		initOptions={{
			onLoad: 'login-required',
		}}
		onTokens={({token}) => {
			keycloak.loadUserInfo().then(user => {
				store.dispatch(loginUser(keycloak));
			});
		}}
		LoadingComponent={<Loading>Authenticating user...</Loading>}>
		<Provider store={store}>
			<PersistGate
				loading={<Loading>Persisting data...</Loading>}
				persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</ReactKeycloakProvider>,
	document.getElementById('root'),
);
