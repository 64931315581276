// Auth
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_FETCH_USER = 'AUTH_FETCH_USER';

// Assessment Templates
export const ASSESSMENT_TEMPLATES_FETCH = 'ASSESSMENT_TEMPLATES_FETCH';

// Current Assessment
export const RESET_CURRENT_ASSESSMENT = 'RESET_CURRENT_ASSESSMENT';
export const SET_CURRENT_ASSESSMENT = 'SET_CURRENT_ASSESSMENT';
export const SET_CURRENT_ASSESSMENT_FIELD = 'SET_CURRENT_ASSESSMENT_FIELD';
export const SET_CURRENT_ASSESSMENT_LOCATION =
	'SET_CURRENT_ASSESSMENT_LOCATION';

export const CURRENT_ASSESSMENT_SET_COASSESSORS =
	'CURRENT_ASSESSMENT_SET_COASSESSORS';

export const UPDATE_QUESTION = 'UPDATE_QUESTION';

export const UPDATE_RECOMMENDATION = 'UPDATE_RECOMMENDATION';
export const ADD_RECOMMENDATION = 'ADD_RECOMMENDATION';
export const REMOVE_RECOMMENDATION = 'REMOVE_RECOMMENDATION';

// UnFinishedAssessments

export const RESET_UNFINISHED_ASSESSMENT = 'RESET_UNFINISHED_ASSESSMENT';
export const UPDATE_UNFINISHED_ASSESSMENT = 'UPDATE_UNFINISHED_ASSESSMENT';
export const ADD_UNFINISHED_ASSESSMENT = 'ADD_UNFINISHED_ASSESSMENT';
export const REMOVE_UNFINISHED_ASSESSMENT = 'REMOVE_UNFINISHED_ASSESSMENT';

// DEV
export const DEV_SET_TEST_DATA = 'DEV_SET_TEST_DATA';
