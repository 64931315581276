import React from 'react';
import {Spinner} from 'reactstrap';

const Loading = ({children}) => {
	return (
		<div className="loader animated fadeIn pt-3 text-center">
			{children}
			<Spinner
				type={'grow'}
				color={'primary'}
				style={{width: '5rem', height: '5rem'}}>
				{''}
			</Spinner>
		</div>
	);
};

export default Loading;
