import {combineReducers} from 'redux';

import auth from './authReducer';
import assessmentTemplates from './assessmentTemplatesReducer';
import unFinishedAssessments from './unFinishedAssessmentsReducer';
import currentAssessment from './currentAssessmentReducer';

const rootReducer = combineReducers({
	auth,
	assessmentTemplates,
	unFinishedAssessments,
	currentAssessment,
});

export default rootReducer;
