import {ActionType} from 'redux-promise-middleware';
import {ASSESSMENT_TEMPLATES_FETCH} from '../actionTypes';

const initialState = [];

const assessmentTemplatesReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${ASSESSMENT_TEMPLATES_FETCH}_${ActionType.Fulfilled}`:
			return action.payload;
		default:
			return state;
	}
};

export default assessmentTemplatesReducer;
