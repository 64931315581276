import {
	ADD_UNFINISHED_ASSESSMENT,
	REMOVE_UNFINISHED_ASSESSMENT,
	RESET_UNFINISHED_ASSESSMENT,
	UPDATE_UNFINISHED_ASSESSMENT,
} from '../actionTypes';

const initialState = [];

const unFinishedAssessmentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_UNFINISHED_ASSESSMENT:
			return state.map(ua => {
				if (ua.unFinishedKey === action.payload.unFinishedKey)
					return action.payload;
				else return ua;
			});
		case ADD_UNFINISHED_ASSESSMENT:
			return [...state, action.payload];
		case REMOVE_UNFINISHED_ASSESSMENT:
			return state.filter(
				ua => ua.unFinishedKey !== action.payload.unFinishedKey,
			);
		case RESET_UNFINISHED_ASSESSMENT:
			return initialState;
		default:
			return state;
	}
};

export default unFinishedAssessmentsReducer;
