import ServiceApi from '../../utils/ServiceApi';
import {AUTH_FETCH_USER, AUTH_LOGIN} from '../actionTypes';

const SERVICE_URL = process.env.REACT_APP_WS_ENDPOINT;

export const loginUser = keycloak => {
	return dispatch => {
		dispatch({
			type: AUTH_LOGIN,
			payload: {
				kc_token: keycloak.token,
				kc_user: keycloak.user,
				realmAccess: keycloak.realmAccess,
				resourceAccess: keycloak.resourceAccess,
			},
		});
		dispatch(getAppUser());
	};
};

export const getAppUser = () => {
	return dispatch => {
		dispatch({
			type: AUTH_FETCH_USER,
			payload: ServiceApi({
				url: `${SERVICE_URL}/users/current/user`,
				method: 'POST',
			}).then(r => r.data),
		});
	};
};
