import {
	AppBreadcrumb2 as AppBreadcrumb,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
import React, {Suspense} from 'react';
import {useSelector} from 'react-redux';
import * as router from 'react-router-dom';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Container} from 'reactstrap';
import getNavigationConfig from '../../_nav';
import Loading from '../../components/Loading';
import getRoutes from '../../routes';
import {userCanAccessApp} from '../../utils/accessControl';

const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

const LoadingComponent = <Loading>Loading...</Loading>;

const DefaultLayout = props => {
	const auth = useSelector(state => state.auth);

	if (!userCanAccessApp(auth)) return <Redirect to="/no-auth" />;

	const routes = getRoutes(auth);

	return (
		<div className="app">
			<AppHeader fixed>
				<Suspense fallback={null}>
					<DefaultHeader />
				</Suspense>
			</AppHeader>
			<div className="app-body">
				<AppSidebar fixed display="lg">
					<AppSidebarHeader />
					<AppSidebarForm />
					<Suspense fallback={null}>
						<AppSidebarNav
							navConfig={getNavigationConfig(auth)}
							{...props}
							router={router}
						/>
					</Suspense>
					<AppSidebarFooter />
				</AppSidebar>
				<main className="main">
					<AppBreadcrumb appRoutes={routes} router={router} />
					<Container id={'mainContainer'} fluid>
						<Suspense fallback={LoadingComponent}>
							<Switch>
								{routes.map((route, idx) => {
									return route.component ? (
										<Route
											key={idx}
											path={route.path}
											exact={route.exact}
											name={route.name}
											render={props => <route.component {...props} />}
										/>
									) : null;
								})}
								<Redirect from="/" to="/dashboard" />
							</Switch>
						</Suspense>
					</Container>
				</main>
			</div>
			<AppFooter>
				<Suspense fallback={null}>
					<DefaultFooter />
				</Suspense>
			</AppFooter>
		</div>
	);
};

export default DefaultLayout;
