import {ActionType} from 'redux-promise-middleware';
import {AUTH_FETCH_USER, AUTH_LOGIN} from '../actionTypes';

const initialState = {
	kc_token: null,
	kc_user: null,
	user: null,
	roles: [],
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_LOGIN:
			return {
				...state,
				...action.payload,
			};
		case `${AUTH_FETCH_USER}_${ActionType.Fulfilled}`:
			return {
				...state,
				user: action.payload,
				roles: action.payload.roles,
			};
		default:
			return state;
	}
};

export default authReducer;
