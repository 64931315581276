import axios from 'axios';
import keycloak from '../keycloak';
import {store} from '../redux/store';

const ServiceApi = axios.create({});

ServiceApi.interceptors.request.use(
	request => {
		const {kc_token: token} = store.getState().auth;

		if (token !== null) {
			request.headers.Authorization = `Bearer ${token}`;
		} else {
			delete request.headers.Authorization;
		}
		return request;
	},
	error => {
		return Promise.reject(error);
	},
);

ServiceApi.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		console.log('error.response.status: ', error.response.status);
		try {
			switch (error.response.status) {
				case 403:
					keycloak.logout();
					break;
				default:
					break;
			}
		} catch (e) {
			// eslint-disable-next-line no-console
		}
		return Promise.reject(error);
	},
);

export default ServiceApi;
