import {ActionType} from 'redux-promise-middleware';
import {
	ADD_RECOMMENDATION,
	CURRENT_ASSESSMENT_SET_COASSESSORS,
	REMOVE_RECOMMENDATION,
	RESET_CURRENT_ASSESSMENT,
	SET_CURRENT_ASSESSMENT,
	SET_CURRENT_ASSESSMENT_FIELD,
	SET_CURRENT_ASSESSMENT_LOCATION,
	UPDATE_QUESTION,
	UPDATE_RECOMMENDATION,
} from '../actionTypes';

const initialState = {};

const currentAssessmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case RESET_CURRENT_ASSESSMENT:
			return initialState;
		case `${SET_CURRENT_ASSESSMENT}_${ActionType.Fulfilled}`:
			return action.payload;
		case SET_CURRENT_ASSESSMENT_FIELD:
			return {
				...state,
				[action.payload.field]: action.payload.value,
			};
		case SET_CURRENT_ASSESSMENT_LOCATION:
			return {
				...state,
				...action.payload,
			};

		case UPDATE_QUESTION:
			return {
				...state,
				children: state.children.map(group => {
					if (group.key === action.payload.groupKey) {
						return {
							...group,
							children: group.children.map(question => {
								if (question.key === action.payload.questionKey) {
									return {
										...question,
										[action.payload.field]: action.payload.value,
									};
								} else return question;
							}),
						};
					} else return group;
				}),
			};

		case UPDATE_RECOMMENDATION:
			return {
				...state,
				children: state.children.map(group => {
					if (group.key === action.payload.groupKey) {
						return {
							...group,
							recommendations: group.recommendations.map(recommendation => {
								if (recommendation.key === action.payload.recommendationKey) {
									return {
										...recommendation,
										[action.payload.field]: action.payload.value,
									};
								} else return recommendation;
							}),
						};
					} else return group;
				}),
			};

		case ADD_RECOMMENDATION:
			return {
				...state,
				children: state.children.map(group => {
					if (group.key === action.payload.groupKey) {
						return {
							...group,
							recommendations: group.recommendations
								? [action.payload.recommendation, ...group.recommendations]
								: [action.payload.recommendation],
						};
					} else return group;
				}),
			};

		case REMOVE_RECOMMENDATION:
			return {
				...state,
				children: state.children.map(group => {
					if (group.key === action.payload.groupKey) {
						return {
							...group,
							recommendations: group.recommendations.filter(
								r => r.key !== action.payload.recommendationKey,
							),
						};
					} else return group;
				}),
			};

		case CURRENT_ASSESSMENT_SET_COASSESSORS:
			return {
				...state,
				coassessors: action.payload,
			};
		// case DEV_SET_TEST_DATA:
		// 	return testData;

		default:
			return state;
	}
};

export default currentAssessmentReducer;
